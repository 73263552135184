<template>
    <div class="row justify-content-center">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <h1 class="mt-4 font-weight-bolder"><i class="fas fa-plus mr-3"></i>Create Milestone</h1>
            <hr>
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-5 p-auto">
                    <div class="card w-100 p-0 shadow-xss border-0 rounded-lg overflow-hidden mr-1 p-3">
                        <form @submit.prevent="postMilestone" class="row p-2">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                <div class="row p-auto">
                                    <div class="col-lg-12 mt-4">
                                        <div class="row">
                                            <div class="form-group col-md-12">
                                                <label for="">Year</label>
                                                <input type="number" class="form-control" v-model="form.year" required>
                                            </div>
                                            <div class="form-group col-md-12">
                                                <label for="">Description</label>
                                                <textarea class="form-control" v-model="form.description" required></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 text-right">
                                        <button type="submit" class="btn btn-current">
                                        Submit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div> 
</template>
<script>
import axios from 'axios'
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.withCredentials = true
export default {
    data(){
        return {
            form : {
                year: '',
                description: '',
            }
        }
    },
    methods: {
        async postMilestone() {
            var data = {
                year: this.form.year,
                description: this.form.description,
            }
            await axios.post(`${process.env.VUE_APP_URL_API}/admin/setting/about/milestones`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then(res => {
                this.$swal({
                    toast: true,
                    title: 'Create Milestone!',
                    text: res.data.data,
                    icon: 'success',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
                this.$router.push({ name: 'SettingsAboutAdmin', hash: '#MILESTONES' })
            }).catch(err => {
                if (err.response.status == 422){
                    this.$swal({
                        toast: true,
                        title: 'Create Milestone!',
                        text: err.response.data.message[0],
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            })
        }
    }
}
</script>